import templateUrl from './social-media-player.component.html';

export default function socialMediaPlayerComponent($log, $timeout, PlayerUtil, $sce, CONFIGURATION) {
	'ngInject';
	return {
		restrict: 'E',
		scope: false, // TODO: Refactor
		bindToController: {},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl,
		link: function(scope, elem) {
			this._socialMediaConfig = CONFIGURATION.socialMedia;
			scope.$on('$destroy', function() {
				$timeout.cancel(timer);
				$timeout.cancel(preloadTimer);
				elem.remove();
			});

			scope.getAsset = function(name) {
				return PlayerUtil.getAssetWithOrientation(scope.$parent.scene.orientation, name);
			};

			elem.css('background-image', `url('${scope.getAsset('bg_social_media.jpg')}'`);

			let preloadCount = 1;

			let errorFn = function(error) {
				scope.data.played = true;
				scope.$emit('playerError', scope.data.id, error);
			};

			let preloadCompleteFn = function() {
				preloadCount--;
				if (preloadCount < 1) {
					$timeout.cancel(preloadTimer);
					scope.$emit('preloadComplete', scope.data.id);
				}
			};

			let preloadTimer = $timeout(function() {
				$log.warn('Failed to load within time limit', {
					data: scope.data
				});

				errorFn(new Error(`SocialMediaPlayer failed to load within time limit for: ${scope.data.url}`));
			}, 15000);

			// Frame setup (dependant on type)
			let frame = angular.element(elem[0].querySelector('.frame'));
			let postMedia;

			if (scope.data.fileType === 'image') {
				postMedia = angular.element('<img>');

				postMedia.on('error', errorFn);
				postMedia.one('load', function() {
					aspectRatioFixer(postMedia, this.naturalHeight, this.naturalWidth); // eslint-disable-line no-invalid-this
					preloadCompleteFn();
				});

				postMedia.attr('src', scope.data.url);
			}
			else if (scope.data.fileType === 'video') {
				postMedia = angular.element('<video disableRemotePlayback />');

				let videoMaxLength = this._socialMediaConfig.videoMaxLength;
				postMedia.on('canplaythrough', function() {
					// Videos longer than max length from settings are dropped
					let duration = this.duration; // eslint-disable-line no-invalid-this
					if (duration > videoMaxLength) {
						return errorFn(new Error(`SocialMediaPlayer video too long: ${duration}`));
					}

					aspectRatioFixer(postMedia, this.videoHeight, this.videoWidth); // eslint-disable-line no-invalid-this
					return preloadCompleteFn();
				});
				postMedia.on('error', () => errorFn(new Error(`SocialMediaPlayer error for: ${scope.data.url}`)));
				postMedia.on('stalled', () => errorFn(new Error(`SocialMediaPlayer stalled for: ${scope.data.url}`)));
				postMedia.on('ended', function() {
					scope.api.stop();
				});
				postMedia[0].volume = 0;

				postMedia.attr('src', scope.data.url);
			}
			else {
				errorFn(new Error('SocialMediaPlayer does not support the type ' + scope.data.fileType));
				return;
			}

			frame.append(postMedia);

			// Replace some data for for official RF posts
			if (scope.data.username === 'roskildefestival') {
				scope.data.template = 'official';
			}

			try	{
				if (scope.data.caption.toLowerCase().indexOf('roskildefestival') > -1) {
					scope.data.caption = scope.data.caption.replace(/(roskildefestival)/gi, '@roskildefestival');
				}
				scope.data.caption = $sce.trustAsHtml(scope.data.caption.replace(/([#@][\S]+)/g, '<span class="is-hashtag">$1</span>'));
			}
			catch (e) {
				$log.warn('Invalid caption in SocialMediaPost. Adding span around hashtags skipped!');
			}

			let timer = null;
			scope.data.played = false;

			scope.api.play = function() {
				scope.data.played = false;
				if (scope.data.fileType === 'image') {
					timer = $timeout(function() {
						scope.api.stop();
					}, scope.data.duration);
				}
				else if (scope.data.fileType === 'video') {
					const playPromise = postMedia[0].play();
					if (playPromise !== undefined) {
						playPromise.then(() => {
							const videoDuration = postMedia[0].duration * 1000;
							let timeoutDuration = videoDuration;
							if (isFinite(timeoutDuration)) {
								timeoutDuration += 100;
							}
							else {
								timeoutDuration = 15000;
							}
							timeoutDuration += 100;
							timer = $timeout(function() {
								$log.warn('SocialMediaPlayer did not complete playing in time ()', {
									timeoutDuration,
									videoDuration,
									data: scope.data,
									scene: scope.$parent.scene
								});
								scope.api.stop();
							}, timeoutDuration);
						}).catch(err => {
							if (err.name === 'NotAllowedError') {
								$log.warn(`SocialMediaPlayer was not allowed to play: ` + err.message, { scene: scope.$parent.scene });
							}
							else {
								errorFn(new Error(`SocialMediaPlayer failed to play for ${scope.data.url}: ` + err.message));
							}
						});
					}
				}
			};

			scope.api.hasPlayed = function() {
				return scope.data.played;
			};

			scope.api.stop = function() {
				scope.data.played = true;
				scope.$emit('playingEnded', scope.data.id);
			};

			function aspectRatioFixer(element, height, width) {
				if (height > width) {
					// Portrait
					element.css('height', '100%');
					element.css('width', 'auto');
				}
				else if (height < width) {
					// Landscape
					element.css('height', 'auto');
					element.css('width', '100%');
				}
				else {
					// Square
					element.css('height', '100%');
					element.css('width', '100%');
				}
			}
		}
	};
}
